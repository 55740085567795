/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.fullscreen {
  --width: 100%;
  --height: 100%;
}

.custom-modal {
  &::part(content) {
    width: 100vw;
    height: 100vh;
  }
}

.pointer {
  cursor: pointer;
}

#toolbar_button_left {
  margin-top: 1vh;
  margin-left: 1vh;
  height: 6vh;
  width: 6vh;
}

#toolbar_button_right {
  margin-top: 1vh;
  margin-right: 1vh;
  height: 6vh;
  width: 6vh;
}

.toolbar_icon {
  height: 6vh;
  width: 6vh;
  filter: drop-shadow(0px 0px 0.1vh black);
}

.toolbar {
  // top: -2vh; // Version ios
  top: 0vh; // Version android_web
}

.title {
  font-size: 2vh;
  font-family: CenturyGothic;
  // margin-top: 0.5vh; // Version ios
  margin-top: 1vh; // Version android_web
}

.title_left {
  text-align: left;
  padding-left: 3vh;
}

.title_left_icon {
  text-align: left;
  // padding-left: 9vh; // Version ios
  padding-left: 1.5vh; // Version android_web
}

.title_right {
  text-align: right;
  padding-right: 3vh;
}

.grid_user {
  // margin-top: 11vh; // Version ios
  margin-top: 8vh; // Version android_web
}

.button {
  text-transform : uppercase;
}

.custom-loader {
  --background: transparent;
  --spinner-color: var(--lig-color-pink);
  color: var(--lig-color-pink);
}
